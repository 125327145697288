.section {
  .title {
    font-family: var(--font-family-secondary);
    color: var(--banner-title-color);
    // font-size: 2.5rem;
    // line-height: 3.5rem;
    font-size: clamp(2.0775rem, 1.9114rem + 1vw, 3rem);
    line-height: clamp(2.5775rem, 2.4114rem + 1vw, 3.5rem);
  }
}

@media (max-width: 1280px) {
}
@media (max-width: 1023px) {
}
@media (max-width: 576px) {
}
